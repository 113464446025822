import React from 'react';
import UserNav from "../../components/nav/UserNav";
//import UserNav from"./components/nav/UserNav"


const Wishlist =()=>(  
    
    <div className ="container-fluid"> 
                          <p></p> 
                          <p></p> 

                        <h4 className=" text-center "> D e s e j o   |P e s s o a l</h4> 
      
        <div className="row  text-center">
        
                

                 <p></p>
        </div>
                 <div className="colmd-2  form-group"> <UserNav/> </div>     
    </div>

)
export default Wishlist;